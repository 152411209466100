'use client'

import { Link } from 'react-router-dom';
import {
  Container,
  Stack,
  Text,
  Button,
  Heading,
  Flex,
  Box
} from '@chakra-ui/react';

export const Home = () => {
  return (
    <Flex alignItems="center" justifyContent="center" h="full">
      <Container maxW="3xl">
        <Stack
          as={Box}
          textAlign="center"
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
          <Heading
            fontWeight={600}
            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%">
            Save time,  <br />
            <Text as="span" color="green.400">
              use our replication tool
            </Text>
          </Heading>
          <Text color="gray.500" fontSize="2xl">
            This tool allows you to easily create <b>state or city based copies</b> for a page,
            using the same content but dynamically modifying specific components.
          </Text>
          <Stack
            direction="column"
            spacing={3}
            align="center"
            alignSelf="center"
            position="relative">
            <Button
              as="a"
              href="https://moneygeek.slite.com/app/docs/eKdqNZybBYuFxo"
              target="_blank"
              variant="link"
              colorScheme="blue"
              size="lg">
              Learn more
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};
