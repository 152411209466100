import { useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from '@chakra-ui/react';

interface ConfirmationProps {
  linkTo?: string
  title?: string
  buttonLabel: string
  bodyDescription: string
  onClickEvent?: any
  shouldBeLoading?: boolean
  shouldBeDisabled?: boolean
};

export const Confirmation = ({
  linkTo,
  title,
  buttonLabel,
  bodyDescription,
  onClickEvent,
  shouldBeLoading = false,
  shouldBeDisabled = true
}: ConfirmationProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const onHandleClick = () => {
    onClose();
    onClickEvent();
  };

  return (<>
    <Button
      variant="solid"
      colorScheme="blue"
      mt={4}
      isDisabled={shouldBeDisabled}
      isLoading={shouldBeLoading}
      onClick={onOpen}>
      {buttonLabel}
    </Button> 
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title || 'Continue to next step?'}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody whiteSpace="pre-line">
          {bodyDescription}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            No
          </Button>
          {linkTo && !onClickEvent && (
            <Link to={linkTo}>
              <Button colorScheme="green" ml={3}>
                Yes
              </Button>
            </Link>
          )}
          {onClickEvent && (
            <Button colorScheme="green" ml={3} onClick={() => onHandleClick()}>
              Yes
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  </>);
};
