'use client'

import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Button,
  Heading,
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormControl,
  FormLabel,
  Input,
  Text,
  Badge,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Icon,
  Progress,
  Link,
  Spinner
} from '@chakra-ui/react';
import { FiCheck, FiRefreshCcw, FiClock, FiExternalLink } from 'react-icons/fi';
import { WiStars } from 'react-icons/wi';
import { useGeneralContext } from '../../App';
import { getFirstItemFromArray, getErrorMessage } from '../../utils/utils';
import { PROCESS_STATUS_LIST, GOOGLE_API_KEY, SHERLOCK_API_URL, SHERLOCK_API_KEY, SHERLOCK_HISTORY_SHEET_ID } from '../../utils/constants';

export const SherlockSpreadsheet = () => {
  const [currentTab, setCurrentTab] = useState<string>('spreadsheet');
  const [googlesheetId, setGooglesheetId] = useState<string>('');
  const [uniqueUrlsList, setUniqueUrlsList] = useState<Array<any>>([]);
  const [sherlockData, setSherlockData] = useState<any>(null);
  const [historyItems, setHistoryItems] = useState<Array<any>>([]);
  const [isReadyToProcess, setIsReadyToProcess] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState<boolean>(true);
  const [hasFinished, setHasFinished] = useState<boolean>(false);
  const [processLog, setProcessLog] = useState<Array<any>>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { accessToken } = useGeneralContext();
  const historyTabRef = useRef(null);

  useEffect(() => {
    setIsReadyToProcess(Boolean(googlesheetId));
  }, [googlesheetId]);

  useEffect(() => {
    if (uniqueUrlsList.length > 0) {
      processUrlList();
    }
  }, [uniqueUrlsList]);

  useEffect(() => {
    if (hasFinished && processLog) {
      addSherlockHistory(processLog.every(item => item.status === 'Success') ? 'Success' : 'Failed');
    }
  }, [hasFinished]);

  useEffect(() => {
    if (sherlockData) {
      addSherlockResults();
    }
  }, [sherlockData]);

  useEffect(() => {
    resetProcess();
  }, [currentTab]);

  const resetProcess = () => {
    setIsProcessing(false);
    setIsLoadingHistory(true);
    setUniqueUrlsList([]);
    setErrorMessage('');
    setProcessLog(null);
    setHistoryItems([]);
    setHasFinished(false);
    setIsReadyToProcess(false);
    setGooglesheetId('');
    getSherlockHistory();
  };

  const sortByDateTime = (a, b) => {
    const dateA = new Date(a[0]);
    const dateB = new Date(b[0]);
  
    // @ts-ignore
    return dateB - dateA;
  };

  const getSherlockHistory = async () => {
    try {
      // Make API request to Google Sheets using the obtained access token
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${SHERLOCK_HISTORY_SHEET_ID}/values/A2:C?majorDimension=ROWS&key=${GOOGLE_API_KEY}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );

      if (response && response.status === 200 && response.data?.values) {
        setHistoryItems(response.data.values.sort(sortByDateTime).map(item => {
          const dateObject = new Date(item[0]);
          const formattedDate = dateObject.toLocaleString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          });
          
          return {
            dateTime: formattedDate,
            link: item[1],
            status: item[2]
          };
        }));
      } else {
        throw new Error('No data found');
      }
    } catch (error) {
      setErrorMessage(`Error loading Sherlock history. ${getErrorMessage(error)}`);
    } finally {
      setIsLoadingHistory(false);
    }
  };

  const addSherlockHistory = async (status) => {
    const isoDatetimeString = new Date().toISOString();

    try {
      await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${SHERLOCK_HISTORY_SHEET_ID}/values/History!A%3AA:append?valueInputOption=USER_ENTERED&key=${GOOGLE_API_KEY}`,
        {
          majorDimension: "ROWS",
          values: [
            [
              isoDatetimeString,
              `https://docs.google.com/spreadsheets/d/${googlesheetId}/`,
              status
            ]
          ]
        }, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );
    } catch (error) {
      setErrorMessage(`Error adding Sherlock history. ${getErrorMessage(error)}`);
    }
  };

  const getGooglesheetData = async () => {
    setIsProcessing(true);
    setUniqueUrlsList([]);
    setErrorMessage('');
    setProcessLog([{
      step: 'Checking the file for URLs',
      hasFinished: false,
      status: 'In execution'
    }]);

    try {
      // Make API request to Google Sheets using the obtained access token
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}/values/A2:A?majorDimension=COLUMNS&key=${GOOGLE_API_KEY}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );

      if (response && response.status === 200 && response.data?.values) {
        const allUrls = response.data.values[0];
        //TODO: Check if sorting is important. Using the Set feature, the values have a different order.
        const filteredArray = Array.from(new Set(allUrls));

        setUniqueUrlsList(filteredArray);
      } else {
        throw new Error('No data available on the file');
      }
    } catch (error) {
      setHasFinished(true);
      setErrorMessage(`Error loading data from the Google Sheet. ${getErrorMessage(error)}`);
      setProcessLog([{
        step: 'Checking the file for URLs',
        hasFinished: true,
        status: 'Error'
      }]);
    } finally {
      setIsProcessing(false);
    }
  };

  const processUrlList = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    setProcessLog([
      {
        step: 'Checking the file for URLs',
        hasFinished: true,
        status: 'Success'
      },
      {
        step: 'Scrapping contacts',
        hasFinished: false,
        status: 'In execution'
      }
    ]);

    try {
      if (uniqueUrlsList.length > 999) {
        setHasFinished(true);
        setErrorMessage('More than 999 URLs were submited, please modify the file and try again');
        setProcessLog([
          {
            step: 'Checking the file for URLs',
            hasFinished: true,
            status: 'Success'
          },
          {
            step: 'Scrapping contacts',
            hasFinished: true,
            status: 'Error'
          }
        ]);
      } else {
        await getSherlockData();
        setProcessLog([
          {
            step: 'Checking the file for URLs',
            hasFinished: true,
            status: 'Success'
          },
          {
            step: 'Scrapping contacts',
            hasFinished: true,
            status: 'Success'
          }
        ]);
      }
    } catch (error) {
      setHasFinished(true);
      setErrorMessage(`Error processing data from the Google Sheet. ${getErrorMessage(error)}`);
      setProcessLog([
        {
          step: 'Checking the file for URLs',
          hasFinished: true,
          status: 'Success'
        },
        {
          step: 'Scrapping contacts',
          hasFinished: true,
          status: 'Error'
        }
      ]);
    } finally {
      setIsProcessing(false);
    };
  };

  const getSherlockData = async () => {
    const urlsParameter = uniqueUrlsList.join(' ');

    try {
      const response = await axios.post(SHERLOCK_API_URL, `apikey=${SHERLOCK_API_KEY}&urls=${urlsParameter}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (response && response.status === 200 && response.data?.data) {
        const allData = response.data.data;
        const processedData = Object.keys(allData).map(item => {
          const contactEmail = getFirstItemFromArray(allData[item].contact_emails, item);
          const sourceUrl = getFirstItemFromArray(allData[item].initiating_urls);

          return [
            contactEmail ? 'Yes' : 'No',
            null,
            sourceUrl,
            contactEmail,
            getFirstItemFromArray(allData[item].contact_names),
            getFirstItemFromArray(allData[item].contact_family_names),
            getFirstItemFromArray(allData[item].contact_titles),
            allData[item].contact_status,
            allData[item].contact_type,
            getFirstItemFromArray(allData[item].source_url_email)
          ]
        });
        const missingUrls = uniqueUrlsList.map(item => {
          return processedData.some(row => row[2] === item) ? null : [
            'No',
            null,
            item,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          ];
        });

        setSherlockData([...processedData, ...missingUrls.filter(Boolean)]);
      } else {
        throw new Error('No data provided from Sherlock');
      }
    } catch (error) {
      throw error;
    }
  };

  const addSherlockResults = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    setProcessLog([
      {
        step: 'Checking the file for URLs',
        hasFinished: true,
        status: 'Success'
      },
      {
        step: 'Scrapping contacts',
        hasFinished: true,
        status: 'Success'
      },
      {
        step: 'Modifying Google Sheet',
        hasFinished: false,
        status: 'In execution'
      }
    ]);

    try {
      const createSheetResponse = await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}:batchUpdate?key=${GOOGLE_API_KEY}`, {
          requests: [
            {
              addSheet: {
                properties: {
                  title: 'Sherlock Result',
                }
              }
            }
          ]
        }, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        }
      );

      if (createSheetResponse?.status === 200) {
        const addDataResponse = await axios.post(
          `https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}/values/Sherlock Result!A%3AA:append?valueInputOption=USER_ENTERED&key=${GOOGLE_API_KEY}`,
          {
            majorDimension: 'ROWS',
            values: [
              [
                'sherlock_found',
                'sherlock_status',
                'SourceURL',
                'contact_email',
                'contact_name',
                'contact_family_name (last name)',
                'contact_title',
                'contact_status',
                'contact_type',
                'source_url_email'
              ],
              ...sherlockData
            ]
          }, {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            }
          }
        );

        if (addDataResponse?.status === 200) {
          setHasFinished(true);
          setProcessLog([
            {
              step: 'Checking the file for URLs',
              hasFinished: true,
              status: 'Success'
            },
            {
              step: 'Scrapping contacts',
              hasFinished: true,
              status: 'Success'
            },
            {
              step: 'Modifying Google Sheet',
              hasFinished: true,
              status: 'Success'
            }
          ]);
        }
      }
    } catch (error) {
      setHasFinished(true);
      setErrorMessage(`Error updating the Google Sheet. ${getErrorMessage(error)}`);
      setProcessLog([
        {
          step: 'Checking the file for URLs',
          hasFinished: true,
          status: 'Success'
        },
        {
          step: 'Scrapping contacts',
          hasFinished: true,
          status: 'Success'
        },
        {
          step: 'Modifying Google Sheet',
          hasFinished: true,
          status: 'Error'
        }
      ]);
    } finally {
      setIsProcessing(false);
    };
  };

  const getProcessView = () => (<>
    <FormControl background="#F6F8FF" p={8} borderRadius="8px">
      <Flex alignItems="flex-end" justifyContent="space-between" gap="5%">
        <Box w="70%">
          <FormLabel htmlFor="google-sheet" mb={1}>
            Google Sheet ID
          </FormLabel>
          <Input
            id="google-sheet"
            value={googlesheetId}
            placeholder="Paste Google Sheet ID here"
            background="white"
            onChange={(e) => setGooglesheetId(e.target.value)} />
        </Box>
        <Button
          w="25%"
          variant="solid"
          colorScheme="blue"
          isDisabled={!isReadyToProcess || hasFinished}
          isLoading={isProcessing}
          onClick={() => getGooglesheetData()}>
          Process
          <Icon boxSize={8} ml={1} as={WiStars} />
        </Button>
      </Flex>
    </FormControl>
    {!isProcessing && !hasFinished && !errorMessage && <HistoryTable isProcessView={true} />}
    {processLog && (<>
      <Heading as="h3" size="md" textAlign="center" my={6}>Progress</Heading>
      <Flex flexDir="column">
        {processLog.map((item, index) => (<Flex key={`progress-list-${index}`} px="15%" alignItems="center" w="100%" gap="5%" mb={4}>
          <Text w="30%">{`Step ${index + 1}: ${item.step}`}</Text>
          <Progress
            w="45%"
            borderRadius="5px"
            isIndeterminate={!item.hasFinished}
            value={item.hasFinished ? 100 : 0} />
          <Badge
            colorScheme={PROCESS_STATUS_LIST[item.status]}
            borderRadius="5px"
            textAlign="center"
            py={1}
            px={2}
            w="15%">
            {item.status}
          </Badge>
        </Flex>))}
      </Flex>
    </>)}
    {errorMessage && (
      <Alert status="error" mt={2}>
        <AlertIcon />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          {errorMessage}
        </AlertDescription>
      </Alert>
    )}
    {hasFinished && (
      <Flex flexDir="column" alignItems="center" mt={2} gap={4}>
        {!errorMessage && (
          <Link href={`https://docs.google.com/spreadsheets/d/${googlesheetId}`} color="blue.500" isExternal>
            See updated Google Sheet <Icon as={FiExternalLink} />
          </Link>
        )}
        <Button
          variant="solid"
          colorScheme="blue"
          mt={errorMessage ? 2 : 0}
          onClick={() => resetProcess()}>
          <Icon boxSize={4} mr={2} as={errorMessage ? FiRefreshCcw : FiCheck} />
          {errorMessage ? 'Retry' : 'Done'}
        </Button>
      </Flex>
    )}
  </>);

  const HistoryTable = ({ isProcessView = false }) => {
    if (isLoadingHistory) {
      return (
        <Flex justifyContent="center" w="full" mt={isProcessView ? 2 : 0}>
          <Spinner size="lg" color="blue.500" />
        </Flex>
      );
    } else if (errorMessage && !isProcessView) {
      return (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>
            {errorMessage}
          </AlertDescription>
        </Alert>
      )
    } else {
      const itemsToShow = isProcessView ? historyItems.slice(0, 5) : historyItems;

      return (<>
        <TableContainer mt={isProcessView ? 2 : 0}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Date & Time</Th>
                <Th>Google Spreadsheet Link</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {itemsToShow.map((item, index) => (
                <Tr key={`history-list-${index}`}>
                  <Td>{item.dateTime}</Td>
                  <Td>
                    <Link href={item.link} color="blue.500" isExternal>
                      {item.link} <Icon as={FiExternalLink} />
                    </Link>
                  </Td>
                  <Td>
                    <Badge
                      colorScheme={PROCESS_STATUS_LIST[item.status]}
                      borderRadius="5px"
                      textAlign="center"
                      py={1}
                      px={2}>
                      {item.status}
                    </Badge>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        {isProcessView && (
          <Button
            mt={4}
            variant="outline"
            colorScheme="blue"
            onClick={() => historyTabRef.current.click()}>
            <Icon boxSize={4} mr={2} as={FiClock} />
            See full history
          </Button>
        )}
      </>);
    }
  };

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <Heading textAlign="center" mb={10}>Sherlock</Heading>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={6}>
        <Tabs w="full">
          <TabList>
            <Tab onClick={() => setCurrentTab('spreadsheet')}>Process</Tab>
            <Tab ref={historyTabRef} onClick={() => setCurrentTab('history')} isDisabled={isProcessing}>History</Tab>
          </TabList>
          <TabPanels mt={6}>
            <TabPanel p={0}>
              {getProcessView()}
            </TabPanel>
            <TabPanel p={0}>
              <HistoryTable />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};
