'use client'

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import {
  Button,
  Heading,
  Flex,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Link,
  Icon,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Checkbox,
  FormControl,
  FormLabel,
  Divider,
  NumberInput,
  NumberInputField,
  Select,
  RadioGroup,
  Radio,
  Stack
} from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/fi';
import { useGeneralContext } from '../App';
import { STATES_LIST, COLLECTIONS_LIST, PAGE_CONFIG } from '../utils/constants';
import { replaceIgnoreCase } from '../utils/utils';
import { ProgressBar } from '../components/ProgressBar';
import { Confirmation } from '../components/Confirmation';

export const SelectAutofill = () => {
  const [pageId, setPageId] = useState<string>('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isEmptyResult, setIsEmptyResult] = useState<boolean>(false);
  const [isReadyToProcess, setIsReadyToProcess] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const {
    config,
    environment,
    setEnvironment,
    collection,
    setCollection,
    stateToProcess,
    setStateToProcess,
    pageData,
    setPageData,
    setPagesToProcess,
    resultPages,
    setResultPages
  } = useGeneralContext();
  const navigate = useNavigate();

  useEffect(() => {
    setEnvironment('dev');
    setCollection('STRAPI_AUTO_OTHER');
    setStateToProcess('Alabama');
    setPageId('');
  }, []);

  useEffect(() => {
    config.current = PAGE_CONFIG[environment];
  }, [environment]);

  useEffect(() => {
    setPageData(null);
    setShowErrorMessage(false);
    setResultPages([]);
    setPagesToProcess([]);
    setIsEmptyResult(false);
  }, [environment, collection, pageId, stateToProcess]);

  useEffect(() => {
    setIsReadyToProcess(resultPages.some(item => item.isChecked));
  }, [resultPages]);

  const updateTableItems = (itemId, newCheckedValue) => {
    setResultPages(prev => prev.map(item => item.id === itemId ? { ...item, isChecked: newCheckedValue } : item));
  };

  const confirmSelectedPages = () => {
    setPagesToProcess(resultPages.filter(item => item.isChecked));
    navigate('/sections');
  };
  
  const handlePageSubmit = async () => {
    setIsProcessing(true);
    setResultPages([]);
    try {
      await axios.get(`${config.current.strapiUrl}/api/${COLLECTIONS_LIST[collection].api}/${pageId}?populate=deep,6`, {
        headers: {
          Authorization: `Bearer ${config.current.token}`
        }
      }).then(async response => {
        const templateData = response.data?.data?.attributes;
        if (!templateData || !templateData.publishing.permalink.includes(stateToProcess.replaceAll(" ", "-").toLowerCase())) {
          setPageData(null);
          setShowErrorMessage(true);
        } else {
          const allStates = Object.keys(STATES_LIST).filter(state => state !== stateToProcess);
          const statePages = await Promise.all(allStates.map(state => new Promise((res) => res(checkForCopyPage(templateData, state)))));
          const formattedPages = [].concat(...statePages);
          const filteredPages = formattedPages.filter(item => item && item['id']);
          setResultPages(filteredPages);
          setPageData(templateData);
          setIsEmptyResult(Boolean(!filteredPages.length));
        }
      });
    } catch {
      setShowErrorMessage(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const checkForCopyPage = async (templateData, state) => {
    try {
      const originalFormattedState = stateToProcess.replaceAll(' ', '-').toLowerCase();
      const newFormattedState = state.replaceAll(' ', '-').toLowerCase();
      const query = qs.stringify({
        publicationState: 'preview',
        populate: 'deep,2',
        filters: {
          publishing: {
            permalink: {
              $eq: replaceIgnoreCase(templateData.publishing.permalink, originalFormattedState, newFormattedState),
            }
          },
        },
      }, {
        encodeValuesOnly: true
      });
      const url = `${config.current.strapiUrl}/api/${COLLECTIONS_LIST[collection].api}?${query}`
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${config.current.token}`
        }
      });

      if (response?.data?.data?.length > 0) {
        return response.data.data.map(strapiData => (
          {
            ...strapiData.attributes,
            id: strapiData.id,
            state: state,
            isChecked: false,
            strapiLink: config.current.strapiUrl + COLLECTIONS_LIST[collection].strapiLink + strapiData.id,
            siteLink: config.current.sitePreviewUrl + strapiData.attributes.publishing.permalink
          }
        ));
      } else {
        return null;
      }
    } catch {
      return null;
    }
  };

  const DuplicatePagesTable = () => (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Strapi CMS Link</Th>
            <Th>State</Th>
            <Th>MoneyGeek Link</Th>
          </Tr>
        </Thead>
        <Tbody>
          {resultPages.map((item, index) => (
            <Tr key={`pages-list${index}`} bg={item.isChecked ? 'blue.100' : 'transparent'}>
              <Td>
                <Checkbox
                  key={`copy-page-checkbox-${index}`}
                  id={`copy-page-checkbox-${index}`}
                  isChecked={item.isChecked}
                  onChange={(e) => updateTableItems(item.id, e.target.checked)} />
              </Td>
              <Td>
                {!item.id ? 'Failed' : (
                  <Link display="flex" alignItems="center" gap={1} href={item.strapiLink} isExternal>
                    <b>{item.id}</b> <Icon as={FiExternalLink} />
                  </Link>
                )}
              </Td>
              <Td>{item.state}</Td>
              <Td>
                {!item.id ? 'No link available' : (
                  <Link display="flex" alignItems="center" gap={1} href={item.siteLink} isExternal>
                    Go to MoneyGeek page <Icon as={FiExternalLink} />
                  </Link>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <ProgressBar processName="smartAutofill" currentStep={0} />
      <Heading textAlign="center" mb={10}>AI Smart Autofill</Heading>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={6}>
        <FormControl>
          <Flex mb={6}>
            <FormLabel htmlFor="environment" mb="0">
              Please select the environment you want to work on:
            </FormLabel>
            <RadioGroup
              id="environment"
              defaultValue={environment}
              onChange={(event) => setEnvironment(event)}>
              <Stack direction="row" gap={3}>
                <Radio value="dev">Development</Radio>
                <Radio value="prod">Production</Radio>
              </Stack>
            </RadioGroup>
          </Flex>
          <Flex alignItems="flex-end" justifyContent="space-between" gap="5%" mb={6}>
            <Box w="30%">
              <FormLabel htmlFor="collection" mb={1}>
                Collection Name
              </FormLabel>
              <Select id="collection" value={collection} onChange={(e) => setCollection(e.target.value)}>
                {Object.keys(COLLECTIONS_LIST).map((item, index) => <option key={`collection-${index}`} value={item}>
                  {COLLECTIONS_LIST[item].name}
                </option>)}
              </Select>
            </Box>
            <Box w="15%">
              <FormLabel htmlFor="page-id" mb={1}>
                Page ID
              </FormLabel>
              <NumberInput id="page-id" value={pageId} min={1} onChange={(e) => setPageId(e)}>
                <NumberInputField />
              </NumberInput>
            </Box>
            <Box w="20%">
              <FormLabel htmlFor="selected-state" mb={1}>
                Template Page State
              </FormLabel>
              <Select id="selected-state" value={stateToProcess} onChange={(e) => setStateToProcess(e.target.value)}>
                {Object.keys(STATES_LIST).map((state, index) => <option key={`state-list-${index}`} value={state}>{state}</option>)}
              </Select>
            </Box>
            <Button w="20%" variant="solid" colorScheme="gray" isLoading={isProcessing} onClick={() => handlePageSubmit()}>Confirm Page</Button>
          </Flex>
        </FormControl>
        {showErrorMessage && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Data error:</AlertTitle>
            <AlertDescription>
              {`It was not possible to identify the page #${pageId} for the collection: ${COLLECTIONS_LIST[collection].name}.
                Please check collection name, ID, and selected state.`}
            </AlertDescription>
          </Alert>
        )}
        {isEmptyResult && (
          <Alert status="warning">
            <AlertIcon />
            <AlertTitle>No data:</AlertTitle>
            <AlertDescription>
              {`There are no Strapi pages using a permalink similar to ${pageData?.publishing.permalink}`}
            </AlertDescription>
          </Alert>
        )}
        {resultPages.length > 0 && (<>
          <Divider mb={6} />
          <DuplicatePagesTable />
        </>)}
      </Box>
      <Confirmation
        buttonLabel="Next step"
        bodyDescription="Please check template page and selected states from the list before moving on."
        onClickEvent={() => confirmSelectedPages()}
        shouldBeDisabled={!isReadyToProcess} />
    </Flex>
  );
};
