'use client'

import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Box
} from '@chakra-ui/react';

const PROCESS_STEPS = {
  pageDuplication: [
    {
      title: 'TEMPLATE PAGE',
      description: 'Select the base page to copy'
    },
    {
      title: 'CONFIGURE COMPONENTS',
      description: 'Select all components to modify'
    },
    {
      title: 'PREVIEW PAGES',
      description: 'List of new copy pages'
    }
  ],
  smartAutofill: [
    {
      title: 'SELECT PAGES',
      description: 'Find and select pages to update'
    },
    {
      title: 'CONFIGURE SECTIONS',
      description: 'Select all sections to modify'
    }
  ],
  rapidRefresh: [
    {
      title: 'SELECT PAGES',
      description: 'Find and select pages to copy'
    },
    {
      title: 'PREVIEW PAGES',
      description: 'List of new copy pages'
    }
  ]
};

interface ProgressBarProps {
  processName: string
  currentStep: number
};

export const ProgressBar = ({ processName, currentStep }: ProgressBarProps) => {
  return (
    <Stepper index={currentStep} w="full" mb={12}>
      {PROCESS_STEPS[processName].map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>
          <Box flexShrink='0'>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>
          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
};
