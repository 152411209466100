'use client'

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Heading,
  Flex,
  Box,
  Tooltip,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Checkbox,
  Badge,
  Spinner,
  Input,
  useToast
} from '@chakra-ui/react';
import { useGeneralContext } from '../App';
import { COLLECTIONS_LIST } from '../utils/constants';
import { ProgressBar } from '../components/ProgressBar';
import { Confirmation } from '../components/Confirmation';

export const SelectSections = () => {
  const [availableSections, setAvailableSections] = useState<Array<any>>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isReadyToProcess, setIsReadyToProcess] = useState<boolean>(false);
  const [selectAllSections, setSelectAllSections] = useState<boolean>(false);
  const { config, collection, pageData, pagesToProcess } = useGeneralContext();
  const navigate = useNavigate();
  const toast = useToast()

  useEffect(() => {
    if (pageData || !pagesToProcess) {
      getDBQuerySections();
    } else {
      navigate('/');
    }
  }, [pageData, pagesToProcess])

  useEffect(() => {
    availableSections && setIsReadyToProcess(availableSections.some(item => item.isChecked && item.noteableID));
  }, [availableSections])

  const getDBQuerySections = () => {
    const pageSections = [];
    let lastHeadline = {
      component: null,
      index: null
    };

    pageData.flex_zone.forEach((component, index) => {
      if (component['__component'] === 'page.headline') {
        lastHeadline.component = component;
        lastHeadline.index = index;
      } else if (isValidDbSection(component, index, lastHeadline, pageData.flex_zone)) {
        pageSections.push({
          ...lastHeadline.component,
          index: index - 1,
          isChecked: false,
          noteableID: null
        });
        lastHeadline = {
          component: null,
          index: null
        };
      }
    });

    setAvailableSections(pageSections);
  };

  const isValidDbSection = (component, index, lastHeadline, flexZone) =>
    component['__component'] === 'database.db-query-table' &&
    lastHeadline.index === index - 2 &&
    flexZone[index - 1]['__component'] === 'page.content';

  const updateCheckedItems = (itemId, newCheckedValue) => {
    setAvailableSections(prev => prev.map(item => item.id === itemId ? { ...item, isChecked: newCheckedValue } : item));
    setSelectAllSections(availableSections.every(item => (item.id === itemId ? newCheckedValue : item.isChecked) && item.noteableID));
  };

  const updateFileIDs = (itemId, newNotableValue) => {
    setAvailableSections(prev => prev.map(item => item.id === itemId ? { ...item, noteableID: newNotableValue } : item));
  };

  const confirmSelectedSections = () => {
    setIsProcessing(true);
    const selectedSections = availableSections.filter(item => item.isChecked && item.noteableID);

    axios.post('https://b55h5evz7izqspwptagobabuju0vsxzf.lambda-url.us-east-2.on.aws/', {
      env: config.current.name,
      collection: COLLECTIONS_LIST[collection].api,
      states: pagesToProcess,
      sections: selectedSections
    }).then(() => {
      navigate('/');
    }).catch(() => {
      toast({
        title: 'Unexpected error',
        description: 'There was a problem executing the autofill process. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsProcessing(false);
    });
  };

  const handleSelectAll = (newValue) => {
    setSelectAllSections(newValue);
    setAvailableSections(prev => prev.map(item => ({ ...item, isChecked: item.noteableID ? newValue : item.isChecked })));
  };

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <ProgressBar processName="smartAutofill" currentStep={1} />
      <Heading textAlign="center" mb={10}>
        {availableSections ? `${availableSections.length} Sections Found on the Template Page` : 'Loading Sections from Template Page'}
      </Heading>
      {!availableSections ? (
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      ) : (<>
        <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={6}>
          {!availableSections.length ? (
            <Alert status="warning">
              <AlertIcon />
              <AlertTitle>Missing data:</AlertTitle>
              <AlertDescription>There are no DB query sections on the template page.</AlertDescription>
            </Alert>
          ) : (
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Tooltip label="Select all rows marked as ready" placement="top">
                      <Th>
                        <Checkbox
                          id="select-all-checkbox"
                          isChecked={selectAllSections}
                          isDisabled={!availableSections.some(item => item.noteableID)}
                          size="lg"
                          onChange={(e) => handleSelectAll(e.target.checked)} />
                      </Th>
                    </Tooltip>
                    <Th>Section title</Th>
                    <Th>Status</Th>
                    <Th>Notable file ID</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {availableSections.map((item, index) => (
                    <Tr key={`pages-list${index}`} bg={item.isChecked ? 'blue.100' : 'transparent'}>
                      <Td>
                        <Checkbox
                          key={`copy-page-checkbox-${index}`}
                          id={`copy-page-checkbox-${index}`}
                          isChecked={item.isChecked}
                          isDisabled={!item.noteableID}
                          size="lg"
                          onChange={(e) => updateCheckedItems(item.id, e.target.checked)} />
                      </Td>
                      <Td>{item.text}</Td>
                      <Td><Badge colorScheme={item.noteableID ? 'green' : 'red'}>{item.noteableID ? 'Ready' : 'Not ready'}</Badge></Td>
                      <Td><Input variant="filled" onChange={(e) => updateFileIDs(item.id, e.target.value)} /></Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <Confirmation
          buttonLabel="Start Process"
          bodyDescription="Please check selected DB query sections from the list before moving on."
          onClickEvent={() => confirmSelectedSections()}
          shouldBeDisabled={!isReadyToProcess}
          shouldBeLoading={isProcessing} />
      </>)}
    </Flex>
  );
};
