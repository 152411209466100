'use client'

import { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  Button,
  Heading,
  Flex,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormControl,
  FormLabel,
  Input,
  Text,
  Badge,
  Select,
  RadioGroup,
  Radio,
  Icon,
  Progress,
  Link
} from '@chakra-ui/react';
import { FiArrowLeft, FiRotateCw, FiExternalLink } from 'react-icons/fi';
import { WiStars } from 'react-icons/wi';
import { useGeneralContext } from '../../App';
import { getErrorMessage, removeDuplicates } from '../../utils/utils';
import { PROCESS_STATUS_LIST, WOODPECKER_MAPPINGS, WP_API_KEY } from '../../utils/constants';
import { MappingTable } from '../../components/MappingTable';
import { PAGE_CONFIG } from '../../utils/constants';

const AVAILABLE_COLUMNS_MAPPING = [
  'id',
  'last_replied',
  'status',
  'updated',
  'email',
  'first_name',
  'last_name',
  'company',
  'industry',
  'website',
  'tags',
  'title',
  'phone',
  'address',
  'city',
  'state',
  'country'
];

export const WoodPeckerContacts = () => {
  const [googlesheetId, setGooglesheetId] = useState<string>('');
  const [campaignId, setCampaignId] = useState<string>('');
  const [campaignType, setCampaignType] = useState<string>('resource marketing');
  const [tags, setTags] = useState<string>('');
  const [mapping, setMapping] = useState<string>('default');
  const [mappingItems, setMappingItems] = useState<any>(WOODPECKER_MAPPINGS.default);
  const [includeReplies, setIncludeReplies] = useState<string>('no');
  
  const [fileColumns, setFileColumns] = useState<Array<any>>([]);
  const [fileData, setFileData] = useState<Array<any>>([]);
  const [contactsData, setContactsData] = useState<Array<any>>([]);
  const [prospectsList, setProspectsList] = useState<Array<any>>([]);
  const [newProspectsList, setNewProspectsList] = useState<Array<any>>([]);
  const [errorsList, setErrorsList] = useState<Array<any>>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [processLog, setProcessLog] = useState<Array<any>>([]);
  const [processResults, setProcessResults] = useState<any>({
    total: 0,
    duplicates: 0,
    lookupErrors: 0,
    rejectedContacts: 0,
    newProspects: 0,
    loadedContacts: 0
  });
  const [configData, setConfigData] = useState<any>({});

  const [isReadyToProcess, setIsReadyToProcess] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [hasFinished, setHasFinished] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { accessToken } = useGeneralContext();

  useEffect(() => {
    setIsReadyToProcess(Boolean(googlesheetId && campaignId && mappingItems && Boolean(Object.keys(mappingItems).length)));
  }, [googlesheetId, campaignId, mappingItems]);

  useEffect(() => {
    setMappingItems(WOODPECKER_MAPPINGS[mapping] || null);
  }, [mapping]);

  useEffect(() => {
    if (isProcessing && !errorMessage && currentStep < steps.length) {
      executeStep();
    } else if (currentStep >= steps.length) {
      const timeout = setTimeout(() => {
        setIsProcessing(false);
        setHasFinished(true);
      }, 1000);
  
      return () => clearTimeout(timeout);
    }
  }, [isProcessing, errorMessage, currentStep]);

  const returnProcess = () => {
    setFileColumns([]);
    setFileData([]);
    setProspectsList([]);
    setNewProspectsList([]);
    setContactsData([]);
    setErrorsList([]);
    setCurrentStep(0);
    setIsReadyToProcess(true);
    setIsProcessing(false);
    setHasFinished(false);
    setErrorMessage('');
    setProcessLog([]);
    setProcessResults({
      total: 0,
      duplicates: 0,
      lookupErrors: 0,
      rejectedContacts: 0,
      newProspects: 0,
      loadedContacts: 0
    });
    setConfigData({});
  };

  const resetProcess = () => {
    setGooglesheetId('');
    setCampaignId('');
    setCampaignType('resource marketing');
    setTags('');
    setMapping('default');
    setIncludeReplies('no');
    setFileColumns([]);
    setFileData([]);
    setProspectsList([]);
    setNewProspectsList([]);
    setContactsData([]);
    setErrorsList([]);
    setCurrentStep(0);
    setIsReadyToProcess(false);
    setIsProcessing(false);
    setHasFinished(false);
    setErrorMessage('');
    setProcessLog([]);
    setProcessResults({
      total: 0,
      duplicates: 0,
      lookupErrors: 0,
      rejectedContacts: 0,
      newProspects: 0,
      loadedContacts: 0
    });
    setConfigData({});
  };

  const startStep = (stepName) => setProcessLog(prev => prev.concat({
    step: stepName,
    hasFinished: false,
    status: 'In execution'
  }));

  const completeStep = (stepName) => setProcessLog(prev => prev.map(item => {
    if (item.step === stepName) {
      item.hasFinished = true;
      item.status = 'Success';
    }

    return item;
  }));

  const markErrorOnStep = (stepName) => setProcessLog(prev => prev.map(item => {
    if (item.step === stepName) {
      item.hasFinished = true;
      item.status = 'Error';
    }

    return item;
  }));

  const executeStep = async () => {
    const step = steps[currentStep];

    try {
      const shouldSkipTags = step.name === 'Adding tag column' && !tags;
      const shouldSkipNewContacts = step.name === 'Loading new prospects to campaign' && !Boolean(newProspectsList.length);
      const shouldSkipExistingContacts = step.name === 'Fetching Woodpecker contacts data' && !Boolean(prospectsList.length);

      if (shouldSkipTags || shouldSkipNewContacts){
        setCurrentStep(prev => prev + 1);
        return;
      } else if (shouldSkipExistingContacts) {
        setCurrentStep(steps.length);
        return;
      }

      startStep(step.name);
      // Execute the current step function
      await step.call();
      // Move to the next step
      completeStep(step.name);
      setCurrentStep(prev => prev + 1);
    } catch (error) {
      setErrorMessage(error.message || error);
      markErrorOnStep(step.name);
    }
  };

  const getCampaignData = async () => {
    try {
      // Make API request to Woodpecker campaign data
      const request = await axios.get(`https://api-1.woodpecker.co/rest/v1/campaign_list?id=${campaignId}`, {
        headers: {
          'Authorization': WP_API_KEY
        }
      });

      if (request && request.status === 200 && Boolean(request.data?.length)) {
        setConfigData(prev => ({
          ...prev,
          campaign: request.data[0]
        }));
      } else {
        throw new Error(`No available campaign ${campaignId}.`);
      }
    } catch (error) {
      throw new Error(`Loading data from Woodpecker. ${getErrorMessage(error)}`);
    }
  };

  const getGooglesheetData = async () => {
    try {
      // Make API request to Google Sheets using the obtained access token
      const fileRequest = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (fileRequest && fileRequest.status === 200 && Boolean(fileRequest.data?.sheets?.length)) {
        setConfigData(prev => ({
          ...prev,
          file: fileRequest.data.properties
        }));
        const firstSheetName = fileRequest.data.sheets[0].properties.title;
        const dataRequest = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}/values/${firstSheetName}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        if (dataRequest && dataRequest.status === 200 && Boolean(dataRequest.data?.values?.length)) {
          const values = dataRequest.data.values.slice(1);
          setFileColumns(dataRequest.data.values[0]);
          setFileData(values);
          setProcessResults(prev => ({
            ...prev,
            total: values.length
          }));
        } else {
          throw new Error('No data available.');
        }
      } else {
        throw new Error('The source is not avaible for fetching.');
      }
    } catch (error) {
      throw new Error(`Loading data from the file. ${getErrorMessage(error)}`);
    }
  };

  const removeDuplicateItems = () => {
    const { duplicates, uniques } = removeDuplicates([...fileData]);
    const emailColumn = fileColumns.findIndex(item => item.includes('email'));
    const duplicatesErrorList = duplicates.map(item => [
      item[emailColumn],
      'Removing duplicates',
      'This is a duplicate email, only the first matching row was loaded'
    ]);
    setErrorsList(prev => prev.concat(duplicatesErrorList));
    setProcessResults(prev => ({
      ...prev,
      duplicates: duplicates.length
    }));
    setFileData(uniques);
  };

  const addTagsColumn = () => {
    setFileData(prev => prev.map(item => {
      const emptyStringsColumnsToAdd = fileColumns.length - item.length;
      return [...item, ...Array(emptyStringsColumnsToAdd).fill(''), tags];
    }));
    setFileColumns(prev => [...prev, 'tag']);
  };

  const applyFileMapping = async () => {
    const missingColumns = [];
    const updatedColumns = [...fileColumns];
    const columnsToSearch = Object.keys(mappingItems);

    columnsToSearch.forEach(item => {
      const columnValue = mappingItems[item];
      const indexToReplace = fileColumns.indexOf(columnValue);
      if (indexToReplace >= 0) {
        updatedColumns[indexToReplace] = item;
      } else {
        missingColumns.push(columnValue);
      }
    });

    if (Boolean(missingColumns.length)) {
      throw new Error(`There are missing mapping columns on the file: ${missingColumns}`);
    } else {
      const list = await Promise.all(fileData.map(item => new Promise((res) => res(createProspectObject(columnsToSearch, updatedColumns, item)))));
      setProspectsList(list);
    }
  };

  const createProspectObject = (selectedColums, allColumns, data) => {
    const singleProspect = {};
    allColumns.forEach((key, index) => {
      if (selectedColums.includes(key)) {
        singleProspect[key] = data[index] || '';
      }
    });
    return singleProspect;
  };

  const loadProspectsToWoodpecker = async () => {
    try {
      // Make API request to Woodpecker without campaign
      const requestBody = { update: 'false', prospects: prospectsList };
      const request = await axios.post('https://api-1.woodpecker.co/rest/v1/add_prospects_campaign', requestBody, {
        headers: {
          'Authorization': WP_API_KEY
        }
      });
  
      if (request && request.status === 200 && Boolean(request.data?.prospects?.length)) {
        const prospectResults = request.data.prospects;
        const prospectsWithErrors = prospectResults.filter(item => !Boolean(item.id));
        const prospectsErrorList = prospectsWithErrors.map(item => [item.email, 'Loading prospects to Woodpecker (without campaign)', item.msg]);
        const newProspects = prospectResults.filter(item => !Boolean(item.status));
        const prospectsToUpdate = prospectResults.filter(item => Boolean(item.id) && Boolean(item.status));
        const contacts = await Promise.all(prospectsToUpdate.map(item => new Promise((res) => res(createContactObject(item)))));
        setProcessResults(prev => ({
          ...prev,
          lookupErrors: prospectsWithErrors.length,
          newProspects: newProspects.length,
          prospectsToUpdate: prospectsToUpdate.length
        }));
        setErrorsList(prev => prev.concat(prospectsErrorList));
        setNewProspectsList(newProspects);
        setProspectsList(contacts);
      } else {
        throw new Error('No prospects were processed.');
      }
    } catch (error) {
      throw new Error(error?.code === 'ERR_BAD_REQUEST' ?
        'All prospects present errors, please check the file and start over.' : `Woodpecker process error. ${getErrorMessage(error)}`);
    }
  };

  const createContactObject = (data) => {
    const contactData = prospectsList.find(prospect => prospect.email === data.email);
    return {
      ...contactData,
      id: data.id
    };
  };

  const loadProspectsToCampaign = async ({ prospects, includeCampaign = true, updateValues = false }) => {
    try {
      // Make API request to Woodpecker with specific campaign
      const requestBody = {
        update: updateValues.toString(),
        prospects: prospects,
        ...includeCampaign && { campaign: { campaign_id: campaignId } }
      };
      const request = await axios.post('https://api-1.woodpecker.co/rest/v1/add_prospects_campaign', requestBody, {
        headers: {
          'Authorization': WP_API_KEY
        }
      });
  
      if (!request || request.status !== 200 || !Boolean(request.data?.prospects?.length)) {
        throw new Error('No prospects were added to campaign.');
      }
    } catch (error) {
      throw new Error(`Woodpecker process error. ${getErrorMessage(error)}`);
    }
  };

  const getContactsData = async () => {
    try {
      const contactsForSearch = prospectsList.map(item => item.id);
      const query = `SELECT id, email, tags, status, last_contacted, has_active_campaign FROM mkt_contact_list where id IN (${contactsForSearch.join(',')})`;
      // Make API request to Woodpecker contacts table
      const request = await axios.post(`${PAGE_CONFIG.dev.api}/rds-files/run/`, { query: query }, {
        headers: {
          'x-api-key': PAGE_CONFIG.dev.apiKey
        }
      });

      if (request && request.status === 200 && request.data?.count >= 1) {
        const formattedData = request.data.data.map(item => ({ ...item, id: parseInt(item.id, 10) }));
        setContactsData(formattedData);
      } else {
        throw new Error('No contacts found on local DB table.');
      }
    } catch (error) {
      throw new Error(`Loading Woodpecker data from local DB table. ${getErrorMessage(error)}`);
    }
  };

  const mergeWoodpeckerData = async () => {
    try {
      const rejectedContacts = [];
      const statusFilter = includeReplies === 'yes' ? ['ACTIVE', 'REPLIED'] : ['ACTIVE']; 
      const contacts = await Promise.all([...prospectsList].map(item => new Promise((res) => res(lookupContact(item)))));
      const filteredContacts: any = contacts.reduce((acc: Array<any>, curr: any) => {
        if (Boolean(curr.elegibleTime) && statusFilter.includes(curr.woodpeckerStatus) && Boolean(!curr.woodpeckerActiveCampaign)) {
          acc.push(curr);
        } else {
          rejectedContacts.push(curr);
        }
        return acc;
      }, []);
      const finalProspectsList = await Promise.all(filteredContacts.map(item => new Promise((res) => res(createFinalProspectObject(item)))));
      const contactsErrorList = rejectedContacts.map(item => [
        item.email,
        'Merge contact data from Woodpecker',
        "Contact doesn't pass elegible time, active/replied status or active campaigns filters" 
      ]);
      setErrorsList(prev => prev.concat(contactsErrorList));
      setProspectsList(finalProspectsList);
      setProcessResults(prev => ({
        ...prev,
        rejectedContacts: rejectedContacts.length,
        loadedContacts: filteredContacts.length
      }));
    } catch (error) {
      throw new Error(error);
    }
  };

  const createFinalProspectObject = (data) => {
    data.tags = `${data.tags.trim()} ${data.woodpeckerTags.trim()}`.trim();
    data.status = 'ACTIVE';
    delete data.id;
    delete data.elegibleTime;
    delete data.woodpeckerStatus;
    delete data.woodpeckerTags;
    return data;
  };

  const lookupContact = async (contact) => {
    const contactValues = contactsData.find(item => item.id === contact.id);
    const daysLimit = campaignType === 'communications' ? 7 : 90;
    const contactHasDate = contactValues?.last_contacted && dayjs(contactValues.last_contacted).isValid();
    let contactObject = {
      ...contact,
      elegibleTime: true,
      woodpeckerStatus: contactValues?.status,
      woodpeckerTags: contactValues?.tags || '',
      woodpeckerActiveCampaign: contactValues?.has_active_campaign
    };

    if (contactHasDate) {
      const daysDifference = dayjs().diff(dayjs(contactValues.last_contacted), 'days');
      contactObject.elegibleTime = daysDifference >= daysLimit ? true : false;
    }
    
    return contactObject;
  };

  const addExcludedContacts = async () => {
    try {
      const createSheetRequest = await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}:batchUpdate`, {
          requests: [
            {
              addSheet: {
                properties: {
                  title: 'Excluded items'
                }
              }
            }
          ]
        }, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );
  
      if (createSheetRequest?.status === 200) {
        const addDataRequest = await axios.post(
          `https://sheets.googleapis.com/v4/spreadsheets/${googlesheetId}/values/Excluded items!A%3AA:append?valueInputOption=USER_ENTERED`,
          {
            majorDimension: 'ROWS',
            values: [['contact_email', 'step', 'failing_reason'], ...errorsList]
          }, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
  
        if (addDataRequest?.status !== 200) {
          throw new Error('The excluded items were not added.');
        }
      } else {
        throw new Error('The new tab was not created.');
      }
    } catch (error) {
      throw new Error(`Adding data to the file. ${getErrorMessage(error)}`);
    }
  };

  const steps = [
    { name: 'Fetching Woodpecker campaign data', call: () => getCampaignData() },
    { name: 'Fetching data from the file', call: () => getGooglesheetData() },
    { name: 'Removing duplicate prospects', call: () => removeDuplicateItems() },
    { name: 'Adding tag column', call: () => addTagsColumn() },
    { name: 'Mapping columns', call: () => applyFileMapping() },
    { name: 'Loading prospects to Woodpecker', call: () => loadProspectsToWoodpecker() },
    { name: 'Loading new prospects to campaign', call: () => loadProspectsToCampaign({ prospects: newProspectsList }) },
    { name: 'Fetching Woodpecker contacts data', call: () => getContactsData() },
    { name: 'Merge contacts data', call: () => mergeWoodpeckerData() },
    { name: 'Updating campaign contacts', call: () => {
      if (includeReplies) {
        loadProspectsToCampaign({ prospects: prospectsList, includeCampaign: false, updateValues: true });
      }
      loadProspectsToCampaign({ prospects: prospectsList, includeCampaign: true, updateValues: true });
    }},
    { name: 'Gererating excluded contacts report', call: () => addExcludedContacts() }
  ];

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <Heading textAlign="center" mb={10}>Woodpecker Loader</Heading>
      <Text mb={8}>This process allows you to upload new contacts into Woodpecker campaigns.</Text>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={8}>
        {!isProcessing && !hasFinished && (
          <Flex background="#F6F8FF" borderRadius="8px" justifyContent="center" py={8}>
            <FormControl w="600px">
              <FormLabel htmlFor="google-sheet" mb={1}>
                Google Sheet ID
              </FormLabel>
              <Input
                id="google-sheet"
                value={googlesheetId}
                placeholder="Paste Google Sheet ID here"
                background="white"
                onChange={(e) => setGooglesheetId(e.target.value)}
                mb={4} />
              <FormLabel htmlFor="campaign" mb={1}>
                Campaign ID
              </FormLabel>
              <Input
                id="campaign"
                value={campaignId}
                background="white"
                onChange={(e) => setCampaignId(e.target.value)}
                mb={4} />
              <FormLabel htmlFor="campaign-type" mb={1}>
                Campaign Type
              </FormLabel>
              <Select id="campaign-type" background="white" mb={4} value={campaignType} onChange={(e) => setCampaignType(e.target.value)}>
                <option value='resource marketing'>Resource Marketing</option>
                <option value='communications'>Communications</option>
                <option value='experts'>Experts</option>
              </Select>
              <FormLabel htmlFor="tags" mb={1}>
                Tags (optional)
              </FormLabel>
              <Input
                id="tags"
                value={tags}
                background="white"
                onChange={(e) => setTags(e.target.value)}
                mb={4} />
              <Flex gap={3} alignItems="flex-end" mb={4}>
                <Box flex={1}>
                  <FormLabel htmlFor="mapping" mb={1}>
                    Mapping
                  </FormLabel>
                  <Select id="mapping" background="white" value={mapping} onChange={(e) => setMapping(e.target.value)}>
                    <option value='default'>Default</option>
                    <option value='comms'>Comms</option>
                    <option value='expert'>Expert</option>
                    <option value='custom'>Custom</option>
                  </Select>
                </Box>
                {mapping === 'custom' && (
                  <MappingTable
                    title="Variables Mapping"
                    bodyDescription='To create a "Custom Map," please map the WP variables with the column names in the table.'
                    buttonLabel="Change"
                    mappingValues={mappingItems}
                    availableItems={AVAILABLE_COLUMNS_MAPPING}
                    onClickEvent={(value) => setMappingItems(value)} />
                )}
              </Flex>
              <FormLabel htmlFor="include-replies" mb={1}>
                Include REPLIED
              </FormLabel>
              <RadioGroup id="include-replies" mb={4} value={includeReplies} onChange={(e) => setIncludeReplies(e)}>
                <Flex gap={5}>
                  <Radio value="yes">
                    Yes, include
                  </Radio>
                  <Radio value="no">
                    No, exclude
                  </Radio>
                </Flex>
              </RadioGroup>
              <Flex justifyContent="center" mt={8}>
                <Button
                  w="150px"
                  variant="solid"
                  colorScheme="blue"
                  isDisabled={!isReadyToProcess}
                  isLoading={isProcessing}
                  onClick={() => setIsProcessing(true)}>
                  Process
                  <Icon boxSize={8} ml={1} as={WiStars} />
                </Button>
              </Flex>
            </FormControl>
          </Flex>
        )}
        {isProcessing && !hasFinished && Boolean(processLog.length) && (<>
          <Heading as="h3" size="md" textAlign="center" mb={6}>Progress</Heading>
          <div>
            {processLog.map((item, index) => (
              <Flex key={`progress-list-${index}`} px="15%" alignItems="center" w="100%" gap="5%" mb={index < processLog.length - 1 ? 6 : 0}>
                <Text w="30%">{`Step ${index + 1}: ${item.step}`}</Text>
                <Progress
                  w="45%"
                  borderRadius="5px"
                  isIndeterminate={!item.hasFinished}
                  value={item.hasFinished ? 100 : 0} />
                <Badge
                  colorScheme={PROCESS_STATUS_LIST[item.status]}
                  borderRadius="5px"
                  textAlign="center"
                  py={1}
                  px={2}
                  w="15%">
                  {item.status}
                </Badge>
              </Flex>
            ))}
          </div>
          {errorMessage && (
            <Box w="auto" mt={8} mx="15%">
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Error:</AlertTitle>
                <AlertDescription>
                  {errorMessage}
                </AlertDescription>
              </Alert>
              <Flex mt={8} justifyContent="center" gap={4}>
                <Button
                  w="150px"
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => returnProcess()}>
                  <Icon boxSize={4} mr={1} as={FiArrowLeft} />
                  Back
                </Button>
                <Button
                  w="150px"
                  variant="outline"
                  colorScheme="blue"
                  onClick={() => resetProcess()}>
                  <Icon boxSize={4} mr={2} as={FiRotateCw} />
                  Start again
                </Button>
              </Flex>
            </Box>
          )}
        </>)}     
        {!isProcessing && hasFinished && (
          <Flex flexDirection="column" alignItems="center">
            <Heading as="h3" size="lg" color="#57C600" mb={6}>Success</Heading>
            <Text mb={2}>The contacts have been successfully added into the campaign.</Text>
            <Text fontWeight="bold" mb={2}>{`Campaign name: ${configData.campaign.name}`}</Text>
            <Link href={`https://docs.google.com/spreadsheets/d/${googlesheetId}`} fontWeight="bold" color="blue.600" isExternal mb={2}>
              {`Spreadsheet: ${configData.file.title}`}
              <Icon ml={1} as={FiExternalLink} />
            </Link>
            <Flex w="full" mt={3} mb={8} justifyContent="center" gap={4} flexWrap="wrap">
              <Badge p="8px 12px">{`Initial contacts: ${processResults.total}`}</Badge>
              <Badge colorScheme="red" p="8px 12px">
                {`Excluded contacts: ${processResults.duplicates + processResults.lookupErrors + processResults.rejectedContacts}`}
              </Badge>
              <Badge colorScheme="blue" p="8px 12px">{`New contacts added: ${processResults.newProspects}`}</Badge>
              <Badge colorScheme="green" p="8px 12px">{`Updated contacts: ${processResults.loadedContacts}`}</Badge>
            </Flex>
            <Button
              w="150px"
              variant="outline"
              colorScheme="blue"
              onClick={() => resetProcess()}>
              <Icon boxSize={4} mr={2} as={FiRotateCw} />
              Start again
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
