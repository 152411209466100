'use client'

import { Link } from 'react-router-dom';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Button,
  useToast,
  Divider
} from '@chakra-ui/react';
import {
  FiMenu,
  FiPlusCircle,
  FiPenTool,
  FiRefreshCcw,
  FiDownload,
  FiLogOut,
  FiMail,
  FiUpload,
  FiMinimize2
} from 'react-icons/fi';
import { FaGoogle } from 'react-icons/fa';
import { IconType } from 'react-icons';
import { useGeneralContext } from '../App';

interface LinkItemProps {
  name: string
  url: string
  icon: IconType
}

const mainItems: Array<LinkItemProps> = [
  { name: 'Flex Page Duplicator', url: '/duplicator/flex', icon: FiPlusCircle },
  { name: 'CC Versus Page Duplicator', url: '/duplicator/template/cc', icon: FiPlusCircle },
  { name: 'Smart Autofill', url: '/autofill', icon: FiPenTool },
  { name: 'Rapid Refresh', url: '/refresh', icon: FiRefreshCcw },
  { name: 'Sherlock', url: '/sherlock', icon: FiDownload }
];

const colabItems: Array<LinkItemProps> = [
  { name: 'Woodpecker Loader', url: '/woodpecker', icon: FiMail },
  { name: 'PD to WP Loader', url: '/pipedrive', icon: FiUpload },
  { name: 'Asana to MoMailer', url: '/asana', icon: FiMinimize2 }
];

export const PageNavigation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box bg="rgba(82, 110, 255, 0.05)">
      <SidebarContent onClose={() => onClose} display={{ base: 'none', lg: 'block' }} />
      <MobileNav display={{ base: 'flex', lg: 'none' }} onOpen={onOpen} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const toast = useToast();
  const { accessToken, setAccessToken } = useGeneralContext();
  
  const login = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/spreadsheets',
    onSuccess: (response) => {
      setAccessToken(response.access_token);
      localStorage.setItem('access_token', response.access_token);
      onClose();
    },
    onError: (error) => {
      toast({
        title: `Login Failed: ${error}`,
        status: 'error',
        duration: 3000,
        isClosable: false,
      });
      onClose();
    }
  });

  const logout = ()=> {
    googleLogout();
    setAccessToken(null);
    localStorage.removeItem('access_token');
    onClose();
  };

  return (
    <Box {...rest}>
      <Flex h="10vh" alignItems="center" px={6} justifyContent="center" display={{ base: 'none', lg: 'flex' }}>
        <Link to="/">
          <Text
            textAlign="center"
            color="chakra-body-text._dark"
            fontSize={{ base: 'xl', lg: '2xl' }}
            fontFamily="monospace"
            fontWeight="bold"
            _hover={{ textDecor: 'none' }}
          >
            MoneyGeek Tools
          </Text>
        </Link>
      </Flex>
      <Flex
        h={{ base: '100vh', lg: '90vh' }}
        flexDir="column"
        justifyContent={{ base: 'flex-start', lg: accessToken ? 'space-between' : 'flex-end' }}
        pb={{ base: 6, lg: 4 }}
        pt={{ base: 6, lg: 0 }}
      >
        <Flex mr={6} justifyContent="flex-end" display={{ base: 'flex', lg: 'none' }}>
          <CloseButton fontSize="xl" onClick={onClose} />
        </Flex>
        {accessToken && (
          <Box>
            {mainItems.map((link, index) => (
              <NavItem key={`nav-main-${index}`} url={link.url} icon={link.icon} onClose={onClose}>
                <Text m={0} fontSize={{ base: '20px', lg: '16px' }}>{link.name}</Text>
              </NavItem>
            ))}
            <Divider w="80%" m="10px 10%" borderWidth="1px" />
            {colabItems.map((link, index) => (
              <NavItem key={`nav-main-${index}`} url={link.url} icon={link.icon} onClose={onClose}>
                <Text m={0} fontSize={{ base: '20px', lg: '16px' }}>{link.name}</Text>
              </NavItem>
            ))}
          </Box>
        )}
        <Button
          p={4}
          mt={{ base: 6, lg: 0 }}
          mx={6}
          borderRadius="lg"
          colorScheme="teal"
          fontSize={{ base: '20px', lg: '16px' }}
          onClick={() => accessToken ? logout() : login()}
        >
          <Icon mr={4} as={accessToken ? FiLogOut : FaGoogle} />
          {accessToken ? 'Logout' : 'Sign in'}
        </Button>
      </Flex>
    </Box>
  )
}

interface NavItemProps extends FlexProps {
  url: string
  icon: IconType
  children: any
  onClose: any
}

const NavItem = ({ url, icon, children, onClose }: NavItemProps) => {
  return (
    <Link to={url} onClick={onClose}>
      <Flex
        align="center"
        p={4}
        mx={6}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}>
        {icon && (
          <Icon
            mr={4}
            fontSize={{ base: '20px', lg: '16px' }}
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  )
}

interface MobileProps extends FlexProps {
  onOpen: () => void
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      px={4}
      height="20"
      alignItems="center"
      borderBottomWidth="1px"
      justifyContent="space-between"
      {...rest}>
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Link to="/">
        <Text
          textAlign="center"
          color="chakra-body-text._dark"
          fontSize="2xl"
          fontFamily="monospace"
          fontWeight="bold"
          _hover={{ textDecor: 'none' }}
        >
          MoneyGeek Tools
        </Text>
      </Link>
    </Flex>
  )
}
