'use client'

import { useState, useEffect } from 'react';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import {
  FiBookmark,
  FiFileText,
  FiLayout,
  FiDatabase,
  FiSettings,
  FiLink,
  FiAtSign,
  FiTag,
  FiBarChart2,
  FiList,
  FiCreditCard,
  FiCheckSquare,
  FiStar,
  FiCopy
 } from 'react-icons/fi';

interface PageIconsProps {
  link: IconType
  title: IconType
  content: IconType
  component: IconType
  db: IconType
  meta: IconType
  settings: IconType
  headline: IconType
  ranking: IconType
  listicle: IconType
  productCard: IconType
  product: IconType
  comparison: IconType
  select: IconType
  companyHighlight: IconType
  hex: IconType
};

const PAGE_ICONS: PageIconsProps = {
  link: FiLink,
  title: FiBookmark,
  content: FiFileText,
  component: FiLayout,
  db: FiDatabase,
  meta: FiAtSign,
  settings: FiSettings,
  headline: FiTag,
  ranking: FiBarChart2,
  listicle: FiList,
  productCard: FiLayout,
  product: FiCreditCard,
  comparison: FiCreditCard,
  select: FiCheckSquare,
  companyHighlight: FiStar,
  hex: FiCopy
};

const COMPONENT_NAMES = {
  permalink: 'Permalink',
  name: 'Name',
  breadcrumb: 'Breadcrumb',
  banner: 'Banner',
  meta: 'Meta',
  hex: 'HEX Settings',
  headline: 'Headline',
  content: 'Content',
  db: 'DB Query Table',
  ranking: 'Summary Ranking',
  listicle: 'DB Query Listicle',
  productCard: 'Query Product Card',
  product: 'CC Product Card',
  comparison: 'CC Comparison Tool',
  companyHighlight: 'Company Highlight',
  companyHighlightQuery: 'Company Highlight Query',
  all: 'Select all components'
};

interface ComponentBoxProps {
  name: string
  icon?: string
  isDefault?: boolean
  isSelectedByParent?: boolean
  onCheckboxChange: any
};

export const ComponentBox = ({ name, icon = name, isDefault = false, isSelectedByParent = false, onCheckboxChange }: ComponentBoxProps) => {
  const [isSelected, setIsSelected] = useState(isDefault || isSelectedByParent);

  useEffect(() => {
    setIsSelected(isSelectedByParent);
  }, [isSelectedByParent]);

  const handleChange = () => {
    onCheckboxChange(name, !isSelected);
    setIsSelected(!isSelected);
  };

  return (
    <Flex
      onClick={() => handleChange()}
      border="1px solid"
      borderColor={isSelected ? "#526EFF" : "#CBCBCB"}
      borderRadius={5}
      background={isSelected ? "white" : "#EFEFEF"}
      pos="relative"
      alignItems="center"
      justifyContent="center"
      flexDir="column"
      pointerEvents={isDefault ? 'none' : 'all'}
      p={5}
      _hover={{ cursor: 'pointer' }}>
      <Icon fontSize="50px" color={isSelected ? "#292929" : "#CBCBCB"} as={PAGE_ICONS[icon as keyof PageIconsProps]} />
      <Text
        color={isSelected ? "#292929" : "#CBCBCB"}
        textAlign="center"
        fontSize="20px"
        fontWeight="600"
        lineHeight="30px"
        mb={0}>
        {COMPONENT_NAMES[name]}
      </Text>
    </Flex>
  );
};
