const STATES_LIST = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Arkansas": "AR",
  "California": "CA",
  "Colorado": "CO",
  "Connecticut": "CT",
  "Delaware": "DE",
  "Florida": "FL",
  "Georgia": "GA",
  "Hawaii": "HI",
  "Idaho": "ID",
  "Illinois": "IL",
  "Indiana": "IN",
  "Iowa": "IA",
  "Kansas": "KS",
  "Kentucky": "KY",
  "Louisiana": "LA",
  "Maine": "ME",
  "Maryland": "MD",
  "Massachusetts": "MA",
  "Michigan": "MI",
  "Minnesota": "MN",
  "Mississippi": "MS",
  "Missouri": "MO",
  "Montana": "MT",
  "Nebraska": "NE",
  "Nevada": "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Ohio": "OH",
  "Oklahoma": "OK",
  "Oregon": "OR",
  "Pennsylvania": "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  "Tennessee": "TN",
  "Texas": "TX",
  "Utah": "UT",
  "Vermont": "VT",
  "Virginia": "VA",
  "Washington": "WA",
  "West Virginia": "WV",
  "Wisconsin": "WI",
  "Wyoming": "WY"
};  

const MONTHS_LIST = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const COLLECTIONS_LIST = {
  STRAPI_AUTO_OTHER: {
    name: "Auto Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::auto-other.auto-other/",
    api: "auto-others"
  },
  STRAPI_AUTO_LOANS_FLEX_PAGE: {
    name: "Auto Loans Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::auto-loans-flex-page.auto-loans-flex-page/",
    api: "auto-loans-flex-pages"
  },
  STRAPI_CREDIT_FLEX_PAGE: {
    name: "Credit Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::credit-flex-page.credit-flex-page/",
    api: "credit-flex-pages"
  },
  STRAPI_HEALTH_FLEX_PAGE: {
    name: "Health Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::health-flex-page.health-flex-page/",
    api: "health-flex-pages"
  },
  STRAPI_HOME_FLEX_PAGE: {
    name: "Home Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::home-flex-page.home-flex-page/",
    api: "home-flex-pages"
  },
  STRAPI_LIFE_FLEX_PAGE: {
    name: "Life Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::life-flex-page.life-flex-page/",
    api: "life-flex-pages"
  },
  STRAPI_MARKETING_FLEX_PAGE: {
    name: "Marketing Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::marketing-flex-page.marketing-flex-page/",
    api: "marketing-flex-pages"
  },
  STRAPI_MORTGAGE_FLEX_PAGE: {
    name: "Mortgage Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::mortgage-flex-page.mortgage-flex-page/",
    api: "mortgage-flex-pages"
  },
  STRAPI_MOTORCYCLE_FLEX_PAGE: {
    name: "Motorcycle Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::motorcycle-flex-page.motorcycle-flex-page/",
    api: "motorcycle-flex-pages"
  },
  STRAPI_PERSONAL_LOANS_FLEX_PAGE: {
    name: "Personal Loans Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::personal-loans-flex-page.personal-loans-flex-page/",
    api: "personal-loans-flex-pages"
  },
  STRAPI_PET_INSURANCE_FLEX_PAGE: {
    name: "Pet Insurance Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::pet-insurance-flex-page.pet-insurance-flex-page/",
    api: "pet-insurance-flex-pages"
  },
  STRAPI_RENTERS_FLEX_PAGE: {
    name: "Renters Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::renters-flex-page.renters-flex-page/",
    api: "renters-flex-pages"
  },
  STRAPI_SMALL_BUSINESS_FLEX_PAGE: {
    name: "Small Business Insurance Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::small-business-flex-page.small-business-flex-page/",
    api: "small-business-flex-pages"
  },
  STRAPI_STUDENT_LOANS_FLEX_PAGE: {
    name: "Student Loans Flex Pages",
    strapiLink: "/dashboard/content-manager/collection-types/api::student-loans-flex-page.student-loans-flex-page/",
    api: "student-loans-flex-pages"
  }
};

const  WOODPECKER_MAPPINGS = {
  default: {
    'email': 'contact_email',
    'first_name': 'contact_name',
    'last_name': 'contact_family_name (last name)',
    'snippet2': 'asset_url',
    'snippet1': 'SourceURL',
    'website': 'SourceDomain',
    'tags': 'tag'
  },
  comms: {
    'email': 'contact_email',
    'first_name': 'contact_name',
    'last_name': 'contact_family_name (last name)',
    'snippet2': 'asset_url',
    'snippet1': 'SourceURL',
    'title': 'contact title',
    'state': 'State',
    'company': 'outlet_name',
    'snippet4': 'Snippet_4 - Rank',
    'snippet5': 'Snippet_5 - Per Capita',
    'snippet6': 'Snippet_6 % of Budget',
    'snippet7': 'Snippet_7 total spend',
    'tags': 'tags'
  },
  expert: {
    'first_name': 'contact_name',
    'last_name': 'contact_family_name (last name)',
    'title': 'Title for WP',
    'email': 'contact_email',
    'city': 'Institution City',
    'state': 'Institution State',
    'website': 'SourceDomain',
    'company': 'Organization Name',
    'snippet1': 'Snippet_1: SourceURL',
    'snippet2': 'Snippet_2: AssetURL',
    'snippet3': 'Snippet_3: University Name',
    'snippet4': 'Snippet_4: Type of Page Pitched',
    'snippet5': 'Snippet_5: Area of Study (Handcraft)'
  }
};

const PSE_COLUMNS = {
  'Prospect Type': 'Contact Type',
  'contact_name': 'First name',
  'contact_family_name (last name)': 'Last name',
  'contact_title': 'Contact Title',
  'contact_email': 'Email',
  'URL Contact Found': 'Contact Collection URL',
  'Expert Discipline': 'Expert Discipline',
  'Expert Subject': 'Expert Subject',
  'Expert Specialities': 'Expert Specialty',
  'responsible_va': 'Collected By',
  'Owner': 'Owner',
  'file_name': 'File Name'
}

const OSE_COLUMNS = {
  'Source URL': 'Media URL',
  'Institution Name': 'University Name',
  'Institution City': 'City/town/village/locality of Address',
  'Institution State': 'State/county of Address',
  'College Name': 'College Name',
  'SourceDomain': 'SourceDomain',
  'Organization Name': 'Name'
}

const DSE_COLUMNS = {
  'Campaign Type': 'Campaign Type',
  'Marketing Campaign': 'Marketing Campaign',
  'Communications & Media 1': 'Media Contact 1',
  'Communications & Media 2': 'Media Contact 2',
  'Deal Name': 'Title',
  'Owner': 'Owner'
}

const CTE_COLUMNS = {
  'Communications': 'Communications',
  'Communications & Media 1': 'Expert Media 1',
  'Communications & Media 2': 'Expert Media 2',
  'Expert': 'Expert',
  'RM': 'Resource Marketing'
}

const PAGE_CONFIG = {
  dev: {
    name: "dev",
    strapiUrl: "https://dev-cms.moneygeek.com",
    siteUrl: "https://dev.moneygeek.com",
    sitePreviewUrl: "https://dev-turbo.moneygeek.com",
    api: "https://dev-api.moneygeek.com",
    apiKey: "D2deasw93rIBIPm8ZlCjaiO5bIQKcFP5y35unBmh",
    token: "712dedee8c90a0c49dc873cf7c9d4ed65b69221186e87027c822b9a7e56553079ad977c14afb82827e409deaac85d4db07bc721aa789bfba177102ee3a7a89eb937f058c7a849545dd067095e2f679b94bac4aa683964eff08a3b0aec5be9a8779b0b20431cb83e310fee5d4f867a4bd907be97f974be111d31d1f9c824b88be"
  },
  prod: {
    name: "prod",
    strapiUrl: "https://cms.moneygeek.com",
    siteUrl: "https://moneygeek.com",
    sitePreviewUrl: "https://turbo.moneygeek.com",
    api: "https://prod-api.moneygeek.com",
    apiKey: "nfBy7vnVsH3STvj2I8e9v6BmxIsOjvh653fpefZR",
    token: "b660bd37c6568a87886ee311e68c57153be4c1889043013db87185353ba3e86027f5207b9c19e5f67b787c4d9456e41ee9ec246ede69665dc2d6ea79dfaa33e41a5d08e8ca205551a1e29ba9dd04e027a6f33d559cff9e5174a7d142a65cdb8bb8fd1f35abfec0fa282e6335a47e0eaba7e43ff286a98f51f40b7b13b41d9cad"
  }
};

const PROCESS_STATUS_LIST = {
  'In execution': 'yellow',
  'Success': 'green',
  'Error': 'red',
  'Failed': 'red'
};

const SHERLOCK_API_URL = 'https://mggalaxy.bigbadhamster.com/sherlockai/api/';
const SHERLOCK_API_KEY = 'SnwC7qE380HB16zfzzQRxxflSLe5owM4NKApp8nV';
const SHERLOCK_HISTORY_SHEET_ID = '1NOlE3FUH1eTnCZoJIv6rTWiR_Chext0QXTF-PDthKM0';

const GOOGLE_CLIENT_ID = '234042637910-d8hpoq038o8i01ue2c278a2b746p1suh.apps.googleusercontent.com';
const GOOGLE_API_KEY = 'AIzaSyBmsi4FrkiIzBx0X3mf4gQXY4GNaH44p0w';

const WP_API_KEY = '71273.9ddb8f62b814df0557fa3b8c14709512ec9164fffa9e64050d7ae78dcbe94bfa';

const ASANA_PAT = '2/1206853231904488/1206854879421779:71d488d6333390cbc2229660a9e8ad4a';

export {
  STATES_LIST,
  MONTHS_LIST,
  COLLECTIONS_LIST,
  WOODPECKER_MAPPINGS,
  PSE_COLUMNS,
  OSE_COLUMNS,
  DSE_COLUMNS,
  CTE_COLUMNS,
  PAGE_CONFIG,
  SHERLOCK_API_URL,
  SHERLOCK_API_KEY,
  GOOGLE_CLIENT_ID,
  GOOGLE_API_KEY,
  SHERLOCK_HISTORY_SHEET_ID,
  PROCESS_STATUS_LIST,
  WP_API_KEY,
  ASANA_PAT
};
