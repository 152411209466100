import { useState, useEffect, useRef } from 'react';
import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogCloseButton,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Icon,
  Input,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer
} from '@chakra-ui/react';
import { FiSliders, FiCheck, FiX, FiPlus } from 'react-icons/fi';

interface ConfirmationProps {
  title: string
  bodyDescription: string
  buttonLabel: string
  mappingValues: any
  availableItems?: Array<any>
  onClickEvent?: any
};

export const MappingTable = ({
  title,
  bodyDescription,
  buttonLabel,
  mappingValues,
  availableItems = [],
  onClickEvent
}: ConfirmationProps) => {
  const [mappingItems, setMappingItems] = useState<Array<any>>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  useEffect(() => {
    resetValues();
  }, [mappingValues]);

  const resetValues = () => {
    const allFields: any = mappingValues ? new Set([...availableItems, ...Object.keys(mappingValues)]) : availableItems;
    setMappingItems([...allFields].map(item => ({
      name: item,
      value: mappingValues?.[item] || '',
      isCustom: false
    })));
  };

  const onCloseDialog = () => {
    resetValues();
    onClose();
  };

  const onHandleClick = () => {
    const formattedMapping = mappingItems.reduce((acc, curr) => {
      if (curr.value) {
        acc[curr.name] = curr.value;
      }
      return acc;
    }, {});
    onClickEvent(formattedMapping);
    onClose();
  };

  const modifyMappingName = (index, newName) => {
    const mappingCopy = [...mappingItems];
    mappingCopy[index] = {
      name: newName,
      value: '',
      isCustom: true
    };
    setMappingItems(mappingCopy);
  };

  const modifyMappingItem = (field, newValue) => {
    setMappingItems(prev => prev.map(item => {
      if (item.name === field) {
        item.value = newValue;
      }
  
      return item;
    }));
  };

  const addMappingItem = () => {
    setMappingItems(prev => prev.concat({
      name: '',
      value: '',
      isCustom: true
    }));
  };

  return (<>
    <Button
      w="150px"
      variant="outline"
      colorScheme="blue"
      onClick={onOpen}>
      {buttonLabel}
      <Icon boxSize={5} ml={1} transform="rotate(90deg)" as={FiSliders} />
    </Button>
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onCloseDialog}
      isOpen={isOpen}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title || 'Variables Mapping'}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody whiteSpace="pre-line">
          {bodyDescription}
          <TableContainer mt={5} height="478px" overflowY="scroll">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textTransform="capitalize">Woodpecker Variable</Th>
                  <Th textTransform="capitalize">Column Title</Th>
                </Tr>
              </Thead>
              <Tbody>
                {mappingItems.map((item, index) => (
                  <Tr key={`mapping-item-${index}`}>
                    <Td>{item.isCustom ? <Input autoFocus value={item.name} onChange={(e) => modifyMappingName(index, e.target.value)} /> : item.name}</Td>
                    <Td>
                      <Input
                        placeholder="Not mapped"
                        value={item.value}
                        onChange={(e) => modifyMappingItem(item.name, e.target.value)} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="space-between">
          <Button variant="outline" colorScheme="blue" onClick={() => addMappingItem()}>
            <Icon boxSize={5} mr={1} as={FiPlus} />
            Add a field
          </Button>
          <Flex gap={3}>
            <Button ref={cancelRef} onClick={onCloseDialog}>
              <Icon boxSize={5} mr={1} as={FiX} />
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={() => onHandleClick()}>
              <Icon boxSize={5} mr={1} as={FiCheck} />
              Save
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  </>);
};
