import axios from 'axios';
import dayjs from 'dayjs';
import { getErrorMessage, removeDuplicates } from '../../utils/utils';
import { WOODPECKER_MAPPINGS, WP_API_KEY } from '../../utils/constants';
import { PAGE_CONFIG } from '../../utils/constants';

const loadWoodpeckerContacts = async (campaignsData, includeReplies, updateProspectStatus, updateProcessResults) => {
  try {
    return await Promise.all(Object.entries(campaignsData).map(
      ([id, data]: any) => new Promise((res) => res(executeSteps(id, data.contacts, includeReplies, updateProspectStatus, updateProcessResults)))
    ));
  } catch (error) {
    throw error;
  }
};

const executeSteps = async (campaignId, contacts, includeReplies, updateProspectStatus, updateProcessResults) => {
  try {
    const uniques = await removeDuplicateItems(contacts, updateProspectStatus, updateProcessResults);
    const prospects = await applyFileMapping(uniques);
    const { newProspects, existingProspects } = await loadProspectsToWoodpecker(prospects, updateProspectStatus, updateProcessResults);
    await loadProspectsToCampaign(campaignId, newProspects);
    if (Boolean(existingProspects.length)) {
      const woodpeckerData = await getContactsData(existingProspects);
      const finalProspects = await mergeWoodpeckerData(existingProspects, woodpeckerData, includeReplies, updateProspectStatus, updateProcessResults);
      await loadProspectsToCampaign(campaignId, finalProspects, !includeReplies, true);

      return [ ...newProspects, ...finalProspects ];
    } else {
      return newProspects;
    }
  } catch (error) {
    throw error;
  }
};

// Step 1
const removeDuplicateItems = (contacts, updateProspectStatus, updateProcessResults) => {
  const { duplicates, uniques } = removeDuplicates([...contacts], 'contact_email');
  const duplicatesErrorList = duplicates.map(item => ({
    hasExtraValidation: true,
    email: item.contact_email,
    deal: item['Deal Name'],
    status: 'WP: This is a duplicate email, only the first matching row was loaded'
  }));
  updateProspectStatus(duplicatesErrorList);
  updateProcessResults({ field: duplicates, value: duplicates.length });

  return uniques;
};

// Step 2
const applyFileMapping = async (contacts) => {
  return await Promise.all(contacts.map(item=> new Promise((res) => res(createProspectObject(WOODPECKER_MAPPINGS.expert, item)))));
};

const createProspectObject = (mappingColumns, prospect) => {
  const singleProspect = {};
  Object.entries(mappingColumns).forEach(([key, value]: any) => {
    if (value in prospect) {
      singleProspect[key] = prospect[value] || '';
    }
  });

  return singleProspect;
};

// Step 3
const loadProspectsToWoodpecker = async (prospects, updateProspectStatus, updateProcessResults) => {
  try {
    // Make API request to Woodpecker without campaign
    const requestBody = { update: 'false', prospects: prospects };
    const request = await axios.post('https://api-1.woodpecker.co/rest/v1/add_prospects_campaign', requestBody, {
      headers: {
        'Authorization': WP_API_KEY
      }
    });

    if (request && request.status === 200 && Boolean(request.data?.prospects?.length)) {
      const prospectResults = request.data.prospects;
      const prospectsWithErrors = prospectResults.filter(item => !Boolean(item.id));
      const prospectsErrorList = prospectsWithErrors.map(item => ({ email: item.email, status: `WP: ${item.msg}` }));
      const newProspects = prospectResults.filter(item => !Boolean(item.status));
      const prospectsToUpdate = prospectResults.filter(item => Boolean(item.id) && Boolean(item.status));
      const contacts = await Promise.all(prospectsToUpdate.map(item => new Promise((res) => res(createContactObject(prospects, item)))));
      updateProspectStatus(prospectsErrorList);
      updateProcessResults({
        list: {
          lookupErrors: prospectsWithErrors.length,
          newProspects: newProspects.length,
          prospectsToUpdate: prospectsToUpdate.length
        }
      });
      
      return {
        newProspects: newProspects,
        existingProspects: contacts
      }
    } else {
      throw new Error('No prospects were processed.');
    }
  } catch (error) {
    throw new Error(error?.code === 'ERR_BAD_REQUEST' ?
      'All prospects present errors, please check the file and start over.' : `Woodpecker process error. ${getErrorMessage(error)}`);
  }
};

const createContactObject = (prospects, item) => {
  const contactData = prospects.find(prospect => prospect.email === item.email);
  return {
    ...contactData,
    id: item.id
  };
};

// Step 4
const loadProspectsToCampaign = async (campaignId, prospects, includeCampaign = true, updateValues = false) => {
  if (!Boolean(prospects.length)) {
    return
  }

  try {
    // Make API request to Woodpecker with specific campaign
    const requestBody = {
      update: updateValues.toString(),
      prospects: prospects,
      ...includeCampaign && { campaign: { campaign_id: campaignId } }
    };
    const request = await axios.post('https://api-1.woodpecker.co/rest/v1/add_prospects_campaign', requestBody, {
      headers: {
        'Authorization': WP_API_KEY
      }
    });

    if (!request || request.status !== 200 || !Boolean(request.data?.prospects?.length)) {
      throw new Error('No prospects were added to campaign.');
    }
  } catch (error) {
    throw new Error(`Woodpecker process error. ${getErrorMessage(error)}`);
  }
};

// Step 5
const getContactsData = async (prospects) => {
  try {
    const contactsForSearch = prospects.map(item => item.id);
    const query = `SELECT id, email, tags, status, last_contacted, has_active_campaign FROM mkt_contact_list where id IN (${contactsForSearch.join(',')})`;
    // Make API request to Woodpecker contacts table
    const request = await axios.post(`${PAGE_CONFIG.dev.api}/rds-files/run/`, { query: query }, {
      headers: {
        'x-api-key': PAGE_CONFIG.dev.apiKey
      }
    });

    if (request && request.status === 200 && request.data?.count >= 1) {
      return request.data.data.map(item => ({ ...item, id: parseInt(item.id, 10) }));
    } else {
      throw new Error('No contacts found on local DB table.');
    }
  } catch (error) {
    throw new Error(`Loading Woodpecker data from local DB table. ${getErrorMessage(error)}`);
  }
};

// Step 6
const mergeWoodpeckerData = async (prospects, woodpeckerData, includeReplies, updateProspectStatus, updateProcessResults) => {
  try {
    const rejectedContacts = [];
    const statusFilter = includeReplies === 'yes' ? ['ACTIVE', 'REPLIED'] : ['ACTIVE']; 
    const contacts = await Promise.all([...prospects].map(item=> new Promise((res) => res(lookupContact(woodpeckerData, item)))));
    const filteredContacts: any = contacts.reduce((acc: Array<any>, curr: any) => {
      if (Boolean(curr.elegibleTime) && statusFilter.includes(curr.woodpeckerStatus) && Boolean(!curr.woodpeckerActiveCampaign)) {
        acc.push(curr);
      } else {
        rejectedContacts.push(curr);
      }
      return acc;
    }, []);
    const contactsErrorList = rejectedContacts.map(item => ({
      email: item.email,
      status: "WP: Contact doesn't pass elegible time, active/replied status or active campaigns filters"
    }));
    const finalProspectsList = await Promise.all(filteredContacts.map(item => new Promise((res) => res(createFinalProspectObject(item)))));
    updateProspectStatus(contactsErrorList);
    updateProcessResults({
      list: {
        rejectedContacts: rejectedContacts.length,
        loadedContacts: finalProspectsList.length
      }
    });

    return finalProspectsList;
  } catch (error) {
    throw new Error(error);
  }
};

const createFinalProspectObject = (item) => {
  const tags = (item.tags || '').trim();
  const woodpeckerTags = (item.woodpeckerTags || '').trim();

  item.tags = `${tags} ${woodpeckerTags}`.trim();
  item.status = 'ACTIVE';
  delete item.id;
  delete item.elegibleTime;
  delete item.woodpeckerStatus;
  delete item.woodpeckerTags;
  return item;
};

const lookupContact = async (woodpeckerData, contact) => {
  const contactValues = woodpeckerData.find(item => item.id === contact.id);
  const contactHasDate = contactValues?.last_contacted && dayjs(contactValues.last_contacted).isValid();
  let contactObject = {
    ...contact,
    elegibleTime: true,
    woodpeckerStatus: contactValues?.status,
    woodpeckerTags: contactValues?.tags || '',
    woodpeckerActiveCampaign: contactValues?.has_active_campaign
  };

  if (contactHasDate) {
    const daysDifference = dayjs().diff(dayjs(contactValues.last_contacted), 'days');
    contactObject.elegibleTime = daysDifference >= 90 ? true : false;
  }
  
  return contactObject;
};

export default loadWoodpeckerContacts;
